import { Box, Container, Grid } from "@mui/material";
import OverflowHiddenPage from "../../components/OverFlowhidden";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { count, getUsers } from "../../redux/slices/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import TableComponent from "../../components/Table/TableComponent";

export default function Dashboard() {
  const dispatch =useDispatch()
  const [users,setUsers]=useState([])
  const [counts,setcounts]=useState([])
  useEffect(()=>{
dispatch(getUsers()).then(unwrapResult).then((data)=>{
  if(data?.status){
setUsers(data?.allUser?.map((item)=>{
  return {_id:item._id,name: item?.firstname + " "+item?.lastname,email:item?.email,mobile:item?.mobile,createdAt:item?.createdAt}
}))
dispatch(count()).then(unwrapResult).then((data)=>{
  if(data?.status){
setcounts(data)
  }
})
  }
})
  },[])
  const headers = [
    { id: "_id", label: "_ID" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "mobile", label: "Mobile" },
    { id: "createdAt", label: "Created At" },
  ];
  const onDelete = async (id) => {
   
  };
  const onEdit = (id) => {
   
  };

  return (
  <OverflowHiddenPage>
    <Box component={"main"}className="h-[90vh] overflow-auto">
      <Container maxWidth={"xl"}>
      <div className="flex items-center justify-between">
            <h3 className="text-lg md:text-3xl font-bold my-5">Dashboard</h3>
            
          </div>
          <Grid container spacing={3} className="mb-5">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className="card  shadow-lg rounded-lg p-1 px-3 w-full h-[80px] bg-yellow-400">
                <h5 className="card-title font-bold text-[24px] text-white">
                  Users
                </h5>
                <p className="text-[20px] text-white">{counts?.userCount}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className="card  shadow-lg rounded-lg p-1 px-3 w-full h-[80px] bg-red-400">
                <h5 className="card-title font-bold text-[24px] text-white">
                  Tutorials
                </h5>
                <p className="text-[20px] text-white">{counts?.tutorialsCount}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className="card  shadow-lg rounded-lg p-1 px-3 w-full h-[80px] bg-green-400">
                <h5 className="card-title font-bold text-[24px] text-white">
                Documentations
                </h5>
                <p className="text-[20px] text-white">{counts?.docsCount}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className="card  shadow-lg rounded-lg p-1 px-3 w-full h-[80px] bg-indigo-400">
                <h5 className="card-title font-bold text-[24px] text-white">
                Orders
                </h5>
                <p className="text-[20px] text-white">{counts?.orderCount}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className="card  shadow-lg rounded-lg p-1 px-3 w-full h-[80px] bg-blue-400">
                <h5 className="card-title font-bold text-[24px] text-white">
                Ui Kits
                </h5>
                <p className="text-[20px] text-white">{counts?.uikitCount}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className="card  shadow-lg rounded-lg text-white p-1 px-3 w-full h-[80px] bg-orange-400">
                <h5 className="card-title font-bold text-[24px]">
                Videos
                </h5>
                <p className="text-[20px] text-white">{counts?.videosCount}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className="card  shadow-lg rounded-lg text-white p-1 px-3 w-full h-[80px] bg-fuchsia-400">
                <h5 className="card-title font-bold text-[24px]">
                Projects
                </h5>
                <p className="text-[20px] text-white">{counts?.projectCount}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className="card  shadow-lg rounded-lg p-1 px-3  text-white w-full h-[80px] bg-sky-400">
                <h5 className="card-title font-bold text-[24px]">
                Courses
                </h5>
                <p className="text-[20px] text-white">{counts?.courseCount}</p>
              </div>
            </Grid>
          </Grid>
        
         <Grid container spacing={3}>
          <Grid item xs={12}>
          <TableComponent
            initialData={users || []}
            headers={headers}
            onEdit={onEdit}
            onDelete={onDelete}
          />
          </Grid>
         </Grid>
      
      </Container>
    </Box>
  </OverflowHiddenPage>
  );
}
